import React, { useEffect, useState } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom'
import { Button, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { usePostCategory } from '../../../Contexts/PostCategoryContext';
import Logo from '../../../Assets/Images/sakshi logo Bl croped.png';
import Logo2 from '../../../Assets/Images/sakshi logo B croped.png';
import { debounce } from 'lodash';

const Header = ({ setMainContentMargin }) => {

  const [styleChange, setStyleChange] = useState(false);
  const [logoSrc, setLogoSrc] = useState(Logo2);
  const [toggle, setToggle] = useState(false);

  const { setPostCategory } = usePostCategory();

  useEffect(() => {
    const changeNavbarStyle = debounce(() => {
      if (window.scrollY >= 40) {
        setStyleChange(true);
        setLogoSrc(Logo); // Change to scrolled logo path
      } else {
        setStyleChange(false);
        setLogoSrc(Logo2); // Change back to default logo path
      }
    }, 20); // Adjust the debounce delay as needed

    window.addEventListener("scroll", changeNavbarStyle);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("scroll", changeNavbarStyle);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount


  const handleCategoryClick = async (category, path) => {
    console.log(category);
    setPostCategory(category);

    navigate(path);

  };

  const navigate = useNavigate();

  const handleToggler = () => {
    setToggle(!toggle);
    setMainContentMargin(!toggle ? '-390px' : '-125px'); // Adjust main content margin
  };

  return (

    <Navbar expand="lg" className={styleChange ? "header sticky-top active p-5 pe-5 pt-3 pb-3 shadow" : "header sticky-top ps-5 pe-5 pt-5 pb-3"}>
      <Navbar.Brand onClick={() => handleCategoryClick("", "/")} className='nav-brand'>
        <img src={logoSrc} alt="" className="logo" />
      </Navbar.Brand>
      <Navbar.Toggle onClick={handleToggler} aria-controls="basic-navbar-nav" />
      
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto nav-menue">
          <Nav.Link onClick={() => handleCategoryClick("", "/")}>Home</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Cover", "/category/cover")}>Cover</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Article", "/category/article")}>Article</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Current Affair", "/category/current-affair")}>Current Affair</Nav.Link>
          <NavDropdown onClick={() => handleCategoryClick("Fiction", "/category/fiction")} title="Fiction" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => handleCategoryClick("Story", "/category/story")}>Story</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleCategoryClick("Poem", "/category/poem")}>Poem</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={() => handleCategoryClick("Studies", "/category/studies")}>Studies</Nav.Link>
          <Nav.Link onClick={() => handleCategoryClick("Studies", "/sk-admin")}>Admin</Nav.Link>
          <Button style={{
            background: '#3b6ab2',
            border: 'none',
            fontWeight: '600',
            width: '160px'
          }} onClick={() => navigate("/magazines")} className='btn shadow ms-0 ms-md-3'><i class="fa-solid fa-book-open-reader" style={{ color: '#fff' }}></i> Read Magazines</Button>
        </Nav>
        {/* <Form className='ms-auto d-flex'>
          <div className='search-box shadow'>
            <input type="text" placeholder="Search" className="mr-sm-2" />
            <i className="fa-solid fa-magnifying-glass"></i>

          </div>
        </Form> */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
